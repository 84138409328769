import React from 'react';
import { List } from 'semantic-ui-react';
import CancelMeetingButton from './CancelMeetingButton';
import AddToCalendarButton from './AddToCalendarButton';
import RescheduleButton from './RescheduleButton';
import ReportNoShowButton from './ReportNoShowButton';
import CancelInviteButton from './CancelInviteButton';
import ReviewButton from './ReviewButton';
import ReInviteButton from './ReInviteButton';

const statusActions = {
  Upcoming: [
    AddToCalendarButton,
    RescheduleButton,
    CancelMeetingButton,
  ],
  Past: [ReportNoShowButton],
  Invited: [CancelInviteButton],
  Completed: [ReviewButton],
  Expired: [ReInviteButton],
};

const JobButtons = ({ job, size = 'medium', display = 'vertical' }) => (statusActions[job.status] ? (
  <List horizontal={display === 'horizontal'}>
    {statusActions[job.status].map((Component) => (
      <List.Item key={Component}>
        <Component job={job} size={size} />
      </List.Item>
    ))}
  </List>
) : null);

export default JobButtons;
