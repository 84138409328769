import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Grid, Header } from 'semantic-ui-react';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import MeetingModal from '../components/Meetings/MeetingModal';
import Timezone from '../components/Settings/Availability/Timezone';
import Loading from '../components/Loading';
import { ALL_MEETINGS } from '../queries/profile';
import DataContext from '../contexts/DataContext';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const CustomEventContainer = ({ event }) => (
  <MeetingModal job={event.meeting}>
    <span>{event.title}</span>
  </MeetingModal>
);

const Schedule = () => {
  const { user } = useContext(DataContext);
  const { loading, data } = useQuery(ALL_MEETINGS);

  if (loading) return <Loading />;

  const localizer = momentLocalizer(moment);
  const convertedTime = (time) => momentTZ.tz(time, user.timeZone);
  const events = data.me.upcomingMeetings.concat(data.me.pastJobs)
    .filter((job) => job.meetingAt).map((job) => ({
      title: job.project.title,
      start: new Date(convertedTime(job.meetingAt).valueOf()),
      end: new Date(convertedTime(job.meetingAt).clone().add(1, 'hours').valueOf()),
      resource: { meeting: job },
      meeting: job,
      type: new Date() < convertedTime(job.meetingAt).toDate() ? 'Upcoming' : 'Past',
    }));

  return (
    <Grid
      container
      stackable
      id="schedule"
    >
      <Grid.Row centered columns={3}>
        <Grid.Column className="timezone">
          <Header textAlign="center">Your timezone</Header>
          <Timezone userTimezone={user.timeZone} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Calendar
          localizer={localizer}
          views={['month', 'week']}
          events={events}
          components={{ event: CustomEventContainer }}
        />
      </Grid.Row>
    </Grid>
  );
};

export default Schedule;
