import React from 'react';
import { Button } from 'semantic-ui-react';
import RescheduleMeeting from '../RescheduleMeeting';

const RescheduleButton = ({ job, size = 'medium' }) => (
  <RescheduleMeeting job={job}>
    <Button size={size} className="basic-btn">
      Reschedule Meeting
    </Button>
  </RescheduleMeeting>
);

export default RescheduleButton;
