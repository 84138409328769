import React from 'react';
import { List } from 'semantic-ui-react';
import CustomLink from '../CustomLink';

const DEXTER_LANDING_SITE = 'https://www.dexterexperts.com';
const LINKS = [
  {
    name: 'Terms of Service',
    url: `${DEXTER_LANDING_SITE}/terms-of-service`,
  },
  {
    name: 'Privacy Policy',
    url: `${DEXTER_LANDING_SITE}/privacy-policy`,
  },
  {
    name: 'Compliance',
    url: `${DEXTER_LANDING_SITE}/compliance`,
  },
  {
    name: 'Contact Us',
    url: `${DEXTER_LANDING_SITE}/contact/inquiry`,
  },
  {
    name: 'FAQs',
    // Update the url to `${process.env.REACT_APP_BASE_URL}/faq` after the FAQ page is done
    url: `${DEXTER_LANDING_SITE}/faq-clients`,
  },
];

const FooterLinks = () => (
  <List horizontal>
    {LINKS.map((link) => (
      <React.Fragment key={link.name}>
        <List.Item>
          <CustomLink onClick={() => window.open(link.url, '_blank')}>
            {link.name}
          </CustomLink>
        </List.Item>
        <List.Item> | </List.Item>
      </React.Fragment>
    ))}
  </List>
);

export default FooterLinks;
