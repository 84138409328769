import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Tab, Icon, Popup, Divider, Grid, Header,
} from 'semantic-ui-react';
import momentTZ from 'moment-timezone';
import DataContext from '../../contexts/DataContext';
import { UPCOMING_MEETINGS, PAST_MEETINGS } from '../../queries/profile';
import MeetingSettings from '../Meetings/MeetingSettings';
import MeetingModal from '../Meetings/MeetingModal';
import ProfileModal from '../Experts/ProfileModal';
import CustomLink from '../CustomLink';
import Review from '../Review';
import CreateProjectButton from '../Projects/CreateProjectButton';

const Meetings = ({ type, loading, data }) => {
  const { user } = useContext(DataContext);

  if (loading) return <Tab.Pane loading>{`${type} meetings`}</Tab.Pane>;

  const meetings = type === 'Upcoming' ? data.me.upcomingMeetings : data.me.pastJobs;

  const renderNoMeetings = () => (
    <div className="no-meeting">
      <Header as="h2" style={{ textAlign: 'center', marginTop: '4rem' }}>
        {`No ${type} meetings`}
      </Header>
      <p>
        {
          type === 'Upcoming'
            ? 'Explore the marketplace by searching for specific keywords'
            : 'Have you recently completed a meeting ? Check back in a few days to see updated information'
        }
      </p>
      <p>{type === 'Upcoming' && 'OR'}</p>
      <p>
        {
          type === 'Upcoming'
            && 'Set up a project and start inviting experts best fitted to answer your questions'
        }
      </p>
      {type === 'Upcoming' && <CreateProjectButton />}
    </div>
  );

  if (meetings.length === 0) {
    return <Tab.Pane>{renderNoMeetings(type)}</Tab.Pane>;
  }

  return (
    <Tab.Pane>
      <Grid container stackable className="meetings">
        {meetings.map((job) => (
          <React.Fragment key={job.id}>
            <Grid.Row>
              <Grid.Column>
                <Grid stackable doubling verticalAlign="middle" columns={4}>
                  <Grid.Column width={4}>
                    <MeetingModal job={job}>
                      <CustomLink>
                        {momentTZ.tz(job.meetingAt, user.timeZone).format('llll z')}
                      </CustomLink>
                    </MeetingModal>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Popup
                      content="Go to the project page"
                      trigger={(
                        <Link to={`/projects/${job.project.id}`} className="project-link">
                          {job.project.title}
                          {' '}
                          <Icon name="arrow right" />
                        </Link>
                      )}
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <ProfileModal expertId={job.user.id}>
                      <CustomLink>
                        <div>
                          <Grid.Row>
                            <Icon name="user" />
                            {job.user.fullName}
                          </Grid.Row>
                          <Grid.Row>
                            <span className="headline">
                              {`${job.expert.title}, ${job.expert.organization.name}`}
                            </span>
                          </Grid.Row>
                        </div>
                      </CustomLink>
                    </ProfileModal>
                  </Grid.Column>
                  <Grid.Column width={2} textAlign="right">
                    <Review job={job} />
                    {type === 'Upcoming' && <MeetingSettings job={job} />}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Divider />
          </React.Fragment>
        ))}
      </Grid>
    </Tab.Pane>
  );
};

const UpcomingTab = () => {
  const { loading, data } = useQuery(UPCOMING_MEETINGS);
  return <Meetings type="Upcoming" loading={loading} data={data} />;
};

const RecentTab = () => {
  const { loading, data } = useQuery(PAST_MEETINGS);
  return <Meetings type="Recent" loading={loading} data={data} />;
};

const MeetingTab = () => {
  const panes = [
    { menuItem: 'Upcoming meetings', render: () => <UpcomingTab /> },
    { menuItem: 'Recent meetings', render: () => <RecentTab /> },
  ];

  return <Tab menu={{ secondary: true, pointing: true }} panes={panes} id="meeting-tab" />;
};

export default MeetingTab;
