class Job {
  constructor(job) {
    Object.assign(this, job);
    this.#setStatus();
  }

  #setStatus() {
    let status = this.overall_status;
    if (['Pending Scheduling', 'Client Reschedule'].includes(this.overall_status)) {
      status = 'Invited';
    }
    if (this.overall_status === 'Scheduled') {
      status = 'Accepted';
      if (this.meetingAt) {
        const ONE_HOUR_AGO = new Date(new Date().getTime() - 1 * 60000);
        if (this.meetingAt < ONE_HOUR_AGO) {
          status = 'Past';
        } else {
          status = 'Upcoming';
        }
      }
    }
    if (['Completed', 'Paid'].includes(this.overall_status)) {
      status = 'Completed';
    }
    if (['No Show', 'Expert No Show', 'Client No Show'].includes(this.overall_status)) {
      status = 'No Show';
    }
    if (['Expired', 'Cancelled'].includes(this.overall_status)) {
      status = 'Cancelled';
    }
    this.status = status;
  }
}

export default Job;
