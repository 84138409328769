import React from 'react';
import { Button } from 'semantic-ui-react';
import CancelJob from '../CancelJob';

const CancelMeetingButton = ({ job, size = 'medium' }) => (
  <CancelJob job={job}>
    <Button size={size} basic negative>
      Cancel Meeting
    </Button>
  </CancelJob>
);

export default CancelMeetingButton;
