import React from 'react';
import { useQuery } from '@apollo/client';
import { Header, Button } from 'semantic-ui-react';
import Loading from '../../Loading';
import ProjectShareModal from '../ProjectShareModal';
import { PROJECT_JOBS } from '../../../queries/project';
import NoProjectJobs from './NoProjectJobs';
import ProjectJobs from './ProjectJobs';

const ManageProjectJobs = ({ project: basicProject }) => {
  const { loading, data } = useQuery(PROJECT_JOBS, {
    variables: { id: basicProject.id },
  });

  if (loading) return <Loading />;

  const projectLinkButton = (
    <ProjectShareModal project={data.project}>
      <Button className="basic-btn">Invite my contacts</Button>
    </ProjectShareModal>
  );

  return (
    <div id="manage-project-jobs">
      <div className="top-share-section">
        {projectLinkButton}
      </div>
      {
        data.project.jobs.length === 0
          ? <NoProjectJobs project={data.project} />
          : <ProjectJobs project={data.project} />
        }
      <div className="bottom-share-section">
        <Header size="large">
          Got experts?
        </Header>
        <div className="description">
          <Header.Subheader>
            Invite your own contacts to the project.
          </Header.Subheader>
          <Header.Subheader>
            Dexter will handle everything else - screening, scheduling, and payments
          </Header.Subheader>
        </div>
        {projectLinkButton}
      </div>
    </div>
  );
};

export default ManageProjectJobs;
