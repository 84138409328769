import React, { useState } from 'react';
import { Icon, List, ListItem } from 'semantic-ui-react';
import moment from 'moment-timezone';
import CustomLink from '../CustomLink';

const BonusDisplay = ({ user, job }) => {
  const totalCredits = job.bonuses.map((x) => -x.credits).reduce((sum, credit) => sum + credit, 0)
    .toFixed(2);
  const [visible, setVisible] = useState(false);
  return (
    <CustomLink onClick={() => setVisible(!visible)}>
      <div>
        {`${totalCredits} bonus`}
        {!visible && <Icon name="angle right" />}
        {visible && <Icon name="angle down" />}
      </div>
      {visible && (
        <List>
          {job.bonuses.map((bonus) => (
            <ListItem key={bonus.id}>
              {`${moment.tz(bonus.createdAt, user.timeZone).format('llll z')} | ${bonus.description}`}
            </ListItem>
          ))}
        </List>
      )}
    </CustomLink>
  );
};

export default BonusDisplay;
