import React from 'react';
import { Card, Icon } from 'semantic-ui-react';
import ProfilePicture from '../../../Experts/ProfilePicture';
import ProfileModal from '../../../Experts/ProfileModal';
import CopyLinkButton from '../../../CopyLinkButton';

const ExpertInformation = ({ job }) => (
  <ProfileModal expertId={job.user.id}>
    <div className="span-link">
      <ProfilePicture expert={job.expert} />
      <Card.Header>
        {job.user.fullName}
        {job.project.team.name === 'Decibio' && (
        <CopyLinkButton
          className="link-btn"
          copyText={job.user.email}
          copyPopMessage="Copied email address!"
          icon="mail"
        />
        )}
      </Card.Header>
      <Card.Meta>
        {`${job.expert.title} ${job.expert.organization.name}`}
      </Card.Meta>
      <Card.Meta>
        {job.expert.country}
      </Card.Meta>
      <Card.Meta>
        <Icon name="university" />
        {`${job.expert.credits} credits`}
      </Card.Meta>
    </div>
  </ProfileModal>
);

export default ExpertInformation;
