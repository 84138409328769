import React from 'react';
import { Card, Label } from 'semantic-ui-react';
import Job from '../../../../models/Job';
import JobActions from './JobActions';
import JobInformation from './JobInformation';
import ExpertInformation from './ExpertInformation';

const STATUS_COLORS = {
  Invited: 'yellow',
  Upcoming: 'blue',
  Accepted: 'blue',
  Past: 'blue',
  Completed: 'teal',
};

const ProjectJobs = ({ project }) => {
  const jobs = project.jobs.map((projectJob) => ({ ...new Job(projectJob), project }));
  const pendingJobs = jobs.filter((job) => job.status === 'Invited');
  const acceptedJobs = jobs.filter((job) => ['Upcoming', 'Past', 'Accepted'].includes(job.status));
  const completedJobs = jobs.filter((job) => job.status === 'Completed');
  const otherJobs = jobs.filter((job) => !['Invited', 'Upcoming', 'Past', 'Accepted', 'Completed'].includes(job.status));

  // Sort for each job group
  pendingJobs.sort((a, b) => b.updatedAt - a.updatedAt);
  acceptedJobs.sort((a, b) => {
    if (project.type === 'Interview') {
      // "Upcoming" jobs come before "Past" jobs
      if (a.status === 'Upcoming' && b.status === 'Past') {
        return -1;
      }
      // "Past" jobs come after "Upcoming" jobs
      if (a.status === 'Past' && b.status === 'Upcoming') {
        return 1;
      }
      // Both are "Upcoming" - sort by meetingAt in ascending order
      if (a.status === 'Upcoming' && b.status === 'Upcoming') {
        return a.meetingAt - b.meetingAt;
      }
      // Both are "Past" - sort by meetingAt in descending order
      if (a.status === 'Past' && b.status === 'Past') {
        return b.meetingAt - a.meetingAt;
      }
      return 0;
    }
    return b.updatedAt - a.updatedAt;
  });
  completedJobs.sort((a, b) => b.updatedAt - a.updatedAt);

  const jobGroups = [
    { name: 'ACCEPTED', jobs: acceptedJobs },
    { name: 'COMPLETED', jobs: completedJobs },
    { name: 'PENDING', jobs: pendingJobs },
    { name: 'OTHER', jobs: otherJobs },
  ];
  return (
    <div className="requests">
      {jobGroups.map((group) => (
        <Card.Group key={group.name} itemsPerRow={2} stackable>
          {group.jobs.map((job) => (
            <Card key={job.id} className="request">
              <Card.Content>
                <div className="status">
                  <Label circular size="mini" color={STATUS_COLORS[job.status] || 'red'} empty />
                  {` ${job.status}`}
                </div>
                <ExpertInformation job={job} />
                <Card.Description>
                  <JobInformation job={job} />
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <JobActions job={job} />
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      ))}
    </div>
  );
};

export default ProjectJobs;
