import React, { useContext } from 'react';
import moment from 'moment-timezone';
import { Rating } from 'semantic-ui-react';
import QualificationResponses from '../../QualificationResponses';
import MeetingModal from '../../../Meetings/MeetingModal';
import CustomLink from '../../../CustomLink';
import BonusDisplay from '../../../Transactions/BonusDisplay';
import { QUALTRICS_SURVEY_RESPONSE_URL } from '../../../../constants/qualtrics';
import DataContext from '../../../../contexts/DataContext';

const JobInformation = ({ job }) => {
  const { user } = useContext(DataContext);
  return (
    <div>
      <div className="information">
        {job.status === 'Completed' && !!job.expert_rating && (
          <Rating
            icon="star"
            rating={job.expert_rating}
            maxRating={5}
            disabled
          />
        )}
      </div>
      <div className="information">
        {job.qualificationResponses?.length > 0 && (
          <div className="link">
            <QualificationResponses job={job}>
              <div className="span-link" role="link">
                View Qualification Responses
              </div>
            </QualificationResponses>
          </div>
        )}
        <div className="link">
          {job.project.type === 'Interview' ? (
            <MeetingModal job={job}>
              <div className="span-link">
                {job.meetingAt && moment.tz(job.meetingAt, user.timeZone).format('ddd, M/D/YY hh:mm a z')}
              </div>
            </MeetingModal>
          ) : job.qualtricsContact?.responseId && (
            <CustomLink
              className="span-link"
              onClick={() => window.open(
                `${QUALTRICS_SURVEY_RESPONSE_URL}RID=${job.qualtricsContact.responseId}&SID=${job.project.qualtricsSurvey.surveyId}`,
                '_blank',
              )}
            >
              View Survey Responses
            </CustomLink>
          )}
        </div>
      </div>
      <div className="information">
        {job.bonuses?.length > 0 && <BonusDisplay user={user} job={job} />}
      </div>
    </div>
  );
};

export default JobInformation;
