import React from 'react';
import { Tab, Header, List } from 'semantic-ui-react';
import SearchLabel from '../../SearchLabel';

const NoProjectJobs = ({ project }) => (
  <Tab.Pane>
    <div className="not-found">
      <Header as="h2">No Requests Found</Header>
      {project.keywords.length > 0 && (
        <>
          <Header>
            Try to searching for experts with these keywords
          </Header>
          <List horizontal>
            {project.keywords.map(
              (tag) => (
                <List.Item key={tag}>
                  <SearchLabel query={tag} />
                </List.Item>
              ),
            )}
          </List>
          <p style={{ marginTop: '1rem' }}>
            <SearchLabel
              query={project.keywords.join(' ')}
              textDisplay="Search experts using all project keywords"
            />
          </p>
        </>
      )}
    </div>
  </Tab.Pane>
);
export default NoProjectJobs;
