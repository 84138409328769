import React from 'react';
import { Button } from 'semantic-ui-react';
import CancelInvite from '../MeetingSettings/CancelInvite';

const CancelInviteButton = ({ job, size = 'medium' }) => (
  <CancelInvite job={job}>
    <Button size={size} basic negative>
      Cancel Invite
    </Button>
  </CancelInvite>
);

export default CancelInviteButton;
