import React, { useContext } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import momentTZ from 'moment-timezone';
import {
  Grid, Header, Icon, Button, List, Divider, Rating,
} from 'semantic-ui-react';
import { MEETING } from '../queries/meeting';
import ZoomInfo from '../components/Meetings/ZoomInfo';
import Loading from '../components/Loading';
import ExpertInfo from '../components/Meetings/ExpertInfo';
import InterviewerInfo from '../components/Meetings/InterviewerInfo';
import JobButtons from '../components/Meetings/JobButtons';
import QualificationResponses from '../components/Projects/QualificationResponses';
import Job from '../models/Job';
import DataContext from '../contexts/DataContext';

const Meeting = () => {
  const { user } = useContext(DataContext);
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, data } = useQuery(MEETING, {
    variables: { id },
  });
  if (loading) return (<Loading />);
  if (error) {
    return (
      <Grid container id="error" textAlign="center">
        <Grid.Row>
          <Header>
            <Icon name="ban" />
            Page Not Found
          </Header>
        </Grid.Row>
        <Grid.Row>
          Oops! The page you are looking for does not exist. It might have been moved or deleted.
        </Grid.Row>
        <Grid.Row>
          <Button className="primary-btn" onClick={() => history.push('/home')}>Home</Button>
        </Grid.Row>
      </Grid>
    );
  }

  const job = new Job(data.job);
  const meetingTime = momentTZ.tz(job.meetingAt, user.timeZone);

  return (
    <Grid container id="meeting">
      <Grid.Row>
        <Header>
          {job.project.title}
          <Header.Subheader>
            <List>
              <List.Item>{job.status}</List.Item>
              {!!job.expert_rating && (
                <List.Item>
                  <Rating
                    icon="star"
                    rating={job.expert_rating}
                    maxRating={5}
                    disabled
                  />
                </List.Item>
              )}
            </List>
          </Header.Subheader>
        </Header>
      </Grid.Row>
      <Grid.Row>
        <List>
          <InterviewerInfo meeting={job} />
        </List>
      </Grid.Row>
      <Grid.Row>
        <List>
          <ExpertInfo
            name={job.user.fullName}
            title={job.expert.title}
            organization={job.expert.organization.name}
          />
        </List>
      </Grid.Row>
      <Grid.Row>
        <div className="grid-container">
          <div className="meeting-datetime" style={{ width: '100%' }}>
            <Header as="h4">
              {job.meetingAt
                ? (
                  <>
                    <div>
                      {meetingTime.format('dddd, MMMM DD, YYYY')}
                    </div>
                    <div>
                      {`${meetingTime.format('hh:mm a')} - 
                    ${meetingTime.add(1, 'hour').format('hh:mm a')} ${meetingTime.format('z')}`}
                    </div>
                  </>
                ) : 'No Meeting Time'}
            </Header>
          </div>
          <div className="meeting-invite">
            <List id="meeting-invite-list">
              <ZoomInfo job={job} />
            </List>
          </div>
          <div className="action-btns">
            <JobButtons job={job} />
            <QualificationResponses job={job}>
              <Button basic color="black">
                View Qualification Responses
              </Button>
            </QualificationResponses>
          </div>
          <div className="secondary-action-btns">
            <Link to="/home" id="view-upcoming-meetings">
              <Icon name="list" />
              View all upcoming meetings
            </Link>
          </div>
          <div className="pro-tip">
            <Divider horizontal><Header as="h4">Pro Tip</Header></Divider>
            <Header as="h5" id="pro-tip-subheader">Check multiple calendars for availability</Header>
            Dexter can check multiple calendars for conflicts and only offer
            the times you’re available
            <Link to="/settings/availability/external_calendars" id="pro-tip-calendar-link"> Add another calendar</Link>
          </div>
        </div>
      </Grid.Row>
    </Grid>
  );
};

export default Meeting;
