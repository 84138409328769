import React, { useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Button } from 'semantic-ui-react';
import DataContext from '../../../contexts/DataContext';
import addToCalendar from '../../../utils/addToCalendar';
import { MEETING_INVITE } from '../../../queries/meeting';

const AddToCalendarButton = ({ job, size = 'medium' }) => {
  const { user } = useContext(DataContext);
  const [handleAddToCalendar] = useLazyQuery(MEETING_INVITE, {
    variables: { id: job.id },
    onCompleted: (data) => addToCalendar({
      job: {
        ...job,
        zoomMeeting: {
          ...job.zoomMeeting,
          meetingInvite: data.job.zoomMeeting.meetingInvite,
        },
      },
      user,
    }),
  });
  return (
    <Button size={size} className="primary-btn" onClick={handleAddToCalendar}>
      Add to Calendar
    </Button>
  );
};

export default AddToCalendarButton;
