import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import ReportNoShow from '../ReportNoShow';
import RescheduleButton from './RescheduleButton';

const ReportNoShowModal = ({ children, job }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
    >
      <Modal.Header>We are sorry that the meeting was a no-show</Modal.Header>
      <Modal.Content>
        We will follow up with the Expert about this.
        In the meantime, would you like to reschedule the call or mark it as a no-show?
        Please note that once marked as a no-show, the meeting cannot be rescheduled.
      </Modal.Content>
      <Modal.Actions>
        <RescheduleButton job={job} />
        <ReportNoShow job={job}>
          <Button basic negative>
            Mark as a No-Show
          </Button>
        </ReportNoShow>
      </Modal.Actions>
    </Modal>
  );
};

const ReportNoShowButton = ({ job, size = 'medium' }) => (
  <ReportNoShowModal job={job}>
    <Button basic negative size={size} content="Report No Show" />
  </ReportNoShowModal>
);

export default ReportNoShowButton;
