import React from 'react';
import { Button } from 'semantic-ui-react';
import ReInviteExpert from '../MeetingSettings/ReInviteExpert';

const ReInviteButton = ({ job, size = 'medium' }) => (
  <ReInviteExpert job={job}>
    <Button size={size} color="black" basic>
      Re-invite this Expert
    </Button>
  </ReInviteExpert>
);

export default ReInviteButton;
