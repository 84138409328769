import React from 'react';
import { useQuery } from '@apollo/client';
import Loading from '../Loading';
import { MEETING_INVITE } from '../../queries/meeting';

const MeetingInvite = ({ jobId }) => {
  const { loading, data } = useQuery(MEETING_INVITE, {
    variables: { id: jobId },
  });
  if (loading) return <Loading />;
  return (
    <div>{data.job.zoomMeeting.meetingInvite}</div>);
};

export default MeetingInvite;
