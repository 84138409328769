import React from 'react';
import { Button } from 'semantic-ui-react';
import ReviewModal from '../ReviewModal';
import Security from '../../../utils/Security';

const ReviewButton = ({ job, size = 'medium' }) => {
  const isProjectOwner = Security.isProjectOwner(job.project);
  if (!isProjectOwner || job.expert_rating) {
    return null;
  }
  return (
    <ReviewModal meeting={job}>
      <Button size={size} className="primary-btn">
        Leave a Review
      </Button>
    </ReviewModal>
  );
};

export default ReviewButton;
