import React, { useContext } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import JobButtons from '../../../Meetings/JobButtons';
import PayBonusModal from '../../../Transactions/PayBonusModal';
import DataContext from '../../../../contexts/DataContext';

const BUTTON_SIZE = 'tiny';

const PayBonusButton = ({ job }) => {
  if (job.expert.stripe_account) {
    return (
      <PayBonusModal job={job}>
        <Button size={BUTTON_SIZE} basic color="black">
          Pay Bonus
        </Button>
      </PayBonusModal>
    );
  }
  return (
    <Popup
      disabled={!!job.expert.stripe_account}
      content="Bonus is only available to Stripe-connected experts"
      trigger={(
        <Button
          size={BUTTON_SIZE}
          basic
          color="black"
          className="disabled-btn"
        >
          Pay Bonus
        </Button>
      )}
    />
  );
};

const JobActions = ({ job }) => {
  const { user } = useContext(DataContext);
  const isDecibio = user.membership.map((x) => x.teamName).includes('Decibio');

  return (
    <div className="actions">
      {/* Remove isDecibio if we want to allow clients to make bonus payments */}
      {isDecibio && (<PayBonusButton job={job} />)}
      <JobButtons job={job} size={BUTTON_SIZE} display="horizontal" />
    </div>
  );
};

export default JobActions;
