import React from 'react';
import { Segment, Grid, Header } from 'semantic-ui-react';
import FooterLinks from './FooterLinks';

const Footer = () => (
  <Segment id="footer">
    <Grid stackable container columns={2}>
      <Grid.Column
        mobile={16}
        tablet={16}
        computer={6}
      >
        <Header>
          DEXTER
          <Header.Subheader>
            Copyright © Dexter Expert Network, Inc. 2025
          </Header.Subheader>
        </Header>
      </Grid.Column>
      <Grid.Column
        mobile={16}
        tablet={16}
        computer={10}
        textAlign="right"
        verticalAlign="middle"
      >
        <FooterLinks />
      </Grid.Column>
    </Grid>
  </Segment>
);

export default Footer;
