import React from 'react';
import { useQuery } from '@apollo/client';
import { Button, Icon, Table } from 'semantic-ui-react';
import moment from 'moment';
import Loading from '../Loading';
import { MEETING_REPORT } from '../../queries/meeting';

const MeetingRecordings = ({ meetingReport }) => (
  <>
    {meetingReport.recordings.map((x) => (
      <Button
        key={x.contentType}
        onClick={() => {
          if (['Video', 'Audio'].includes(x.contentType)) {
            window.open(x.playUrl);
          }
          if (x.contentType === 'Transcript') {
            window.open().document.write(`<pre>${x.content}</pre>`);
          }
        }}
      >
        {x.contentType}
      </Button>
    ))}
  </>
);

const MeetingReport = ({ jobId }) => {
  const { loading, data } = useQuery(MEETING_REPORT, {
    variables: { id: jobId },
  });
  if (loading) {
    return <Loading />;
  }
  const { meetingReport } = data.job.zoomMeeting;
  if (meetingReport.status === 'Complete') {
    return (
      <>
        <MeetingRecordings meetingReport={meetingReport} />
        <Table basic="very" padded stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Icon name="user" />
                Name
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Icon name="sign in" />
                Join time
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Icon name="sign out" />
                Leave time
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Icon name="stopwatch" />
                Duration
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {meetingReport.participants.map(
              (participant) => (
                <Table.Row key={`${participant.name}${participant.joinedAt}`}>
                  <Table.Cell>{participant.name}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {moment(participant.joinedAt).format('MM/DD/YYYY, hh:mm:ss A')}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {moment(participant.leftAt).format('MM/DD/YYYY, hh:mm:ss A')}
                  </Table.Cell>
                  <Table.Cell>{`${participant.numMinutes} minute(s)`}</Table.Cell>
                </Table.Row>
              ),
            )}
          </Table.Body>
        </Table>
      </>
    );
  }
  return '(None - Meeting did not take place)';
};

export default MeetingReport;
